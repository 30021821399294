<template>
  <overflowYHidden>
    <div class="deviceMallList">
      <div class="seachinput">
        <div class="vanSearch">
          <searchInput
              v-model="baseData.query"
              @search="inputSearch"
          ></searchInput>
        </div>
        <div class="searchMore" @click="showPopuo">
          筛选
          <van-icon name="arrow-down"/>
        </div>
      </div>
      <div class="searchAbility">
        <div
            class="item"
            v-for="item in abilityList"
            :key="item.key"
            @click="ability(item)"
        >
          <div class="title">{{ item.title }}</div>
          <div
              class="tips"
              :class="{ abilityActive: abilityActive == item.key }"
          >
            {{ item.tips }}
          </div>
        </div>
        <div
            class="item"
        >
          <div
              class="total"
          >
            共 <span style="font-size: 16px"> {{ totalCount }}</span> 条
          </div>
        </div>
      </div>
      <van-pull-refresh v-model="loading" @refresh="deviceOrderList">

        <van-list
            class="content"
            v-model="loading"
            :finished="finished"
            finished-text=""
            @load="onLoad"
        >

          <mallItem
              v-for="(item, index) in list"
              :key="index"
              :dataInfo="item"
          ></mallItem>

        </van-list>


<!--        <div class="content" v-show="list.length > 0">-->
<!--          <mallItem-->
<!--              v-for="(item, index) in list"-->
<!--              :key="index"-->
<!--              :dataInfo="item"-->
<!--          ></mallItem>-->
<!--        </div>-->
<!--        <div v-show="list.length == 0" class="emptyView">-->
<!--          <KcEmpty></KcEmpty>-->
<!--        </div>-->
      </van-pull-refresh>
      <seachPopup ref="seachPopup" @searchEvent="searchEvent"></seachPopup>
    </div>
  </overflowYHidden>
</template>

<script>
import overflowYHidden from '@/components/overflowYHidden'
import seachPopup from './popup/seachPopup'
import mallItem from './mallItem'
import deviceBorrowMixin from '@views/shareAndDispatch/deviceBorrow/deviceBorrowMixin'

export default {
  name: 'deviceMallList',
  components: { seachPopup, mallItem, overflowYHidden },
  mixins: [deviceBorrowMixin],
  data() {
    return {
      finished:false,
      baseData: {
        query: '',
        brandId: '',
        categoryId: '',
        tenantId:'',
      },
      abilityList: [
        {
          title: '口碑推荐',
          tips: '猜你喜欢',
          key: 9
        },
        {
          title: '热租仪器',
          tips: '旗舰品牌',
          key: 10
        }
        // {
        //   title: "常用设备",
        //   tips: "即刻使用",
        //   key: 3,
        // },
        // {
        //   title: "科室必备",
        //   tips: "电子仪器",
        //   key: 4,
        // },
      ],
      abilityActive: 9,
      list: [],
      page: 1,
      pageSize: 30,
      totalCount: 0,
      loading: false
    }
  },
  created() {
    this.searchEvent()
  },
  methods: {
    ability(item) {
      if (this.abilityActive == item.key) {
        this.abilityActive = 6
      } else {
        this.abilityActive = item.key
      }
      this.searchEvent()
    },
    showPopuo() {
      this.$refs.seachPopup.showPopuo()
    },
    inputSearch() {
      this.$refs.seachPopup.query = this.baseData.query
      this.$refs.seachPopup.searchEvent()
    },
    searchEvent(res) {
      if (typeof res == 'object') {
        this.baseData = res
      }
      this.page = 1
      this.$nextTick(() => {
        this.deviceOrderList()
      })
    },
    onLoad:function() {
      this.page = this.page + 1;
      this.deviceOrderList()
    },
    deviceOrderList() {
      this.$showLoading({
        target: '.deviceMallList'
      })
      this.$api.deviceBorrowModule
          .deviceOrderList({
            query: this.baseData.query,
            queryType: this.abilityActive,
            efficiency: 1,
            page: this.page,
            pageSize: this.pageSize,
            categoryId: this.baseData.categoryId,
            brandId: this.baseData.brandId,
            // powerOff: 1,
            tenantIds:this.baseData.tenantId,
            isTenantIdChild:1,
          })
          .then((res) => {
            this.loading = false
            this.totalCount = res.totalCount
            if (this.page == 1) {
              this.list = res.data
            } else {
              this.list.push(...res.data)
            }

            this.finished = this.list.length >= res.totalCount
            this.$hideLoading({
              target: '.deviceMallList'
            })
          })
          .catch((res) => {
            this.$hideLoading({
              target: '.deviceMallList'
            })
            this.loading = false
          })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@styles/variables.scss";

.deviceMallList {
  // height: 100%;
  background: linear-gradient(
          360deg,
          rgba(114, 171, 253, 0) 0%,
          #568efc 70%,
          #3e73fb 100%
  );
  background-size: 100% 195px;
  background-repeat: no-repeat;
  padding: 8px 10px;

  .seachinput {
    display: flex;

    .vanSearch {
      flex: 1;
    }

    .searchMore {
      width: 56px;
      color: $background_color2;
      line-height: 32px;
      font-size: 12px;
      text-align: right;
      text-align: center;

      ::v-deep .van-icon {
        padding-left: 6px;
      }
    }
  }

  .searchAbility {
    display: flex;
    color: $background_color2;
    padding: 12px 0;
    justify-content: center;

    .item {
      width: 86px;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      position: relative;
      flex: 1;

      .tips {
        line-height: 18px;
        border-radius: 9px;
        width: 60px;
        font-size: 12px;
        font-weight: 400;
        margin: 0 auto;
      }

      .total {
        position: relative;
        top: 50%;
        transform: translate(0px, -50%);
        line-height: 18px;
        border-radius: 9px;
        width: 60px;
        font-size: 12px;
        font-weight: 400;
        margin: 0 auto;
      }

      .abilityActive {
        background: #fd1929;
      }

      &::before {
        content: "";
        background: $background_color2;
        width: 1px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }

      &:first-child {
        &::before {
          width: 0px;
        }
      }
    }
  }

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .emptyView {
    height: 360px;
  }
}
</style>