<template>
  <van-popup v-model="show" position="top">
    <div class="vanSearch">
      <van-search
        v-model="query"
        shape="round"
        show-action
        placeholder="请输入搜索内容"
      >
        <template #action>
          <div @click="searchEvent">搜索</div>
        </template>
      </van-search>
    </div>
    <div class="searchContent">
      <div class="searchHistory" v-if="historyStorage.length > 0">
        <div class="header">
          搜索历史 <span class="del" @click="clearHistory(1)">清除</span>
        </div>
        <div class="hiddenScroll">
          <div class="searchList">
            <span
              v-for="(item, index) in historyStorage"
              :key="index"
              :class="{ historyActive: query == item.name }"
              class="item"
              @click="chooseItem(1, item)"
            >
              {{ item.name }}
            </span>
          </div>
        </div>
      </div>
      <div class="searchHistory">
        <div class="header" @click.stop="tenantSelectEvent">
          科室
          <span class="del" v-if="tenantId!=''" @click.stop="tenantId='';tenantName='全部科室';">取消选择</span>
        </div>
        <div class="hiddenScroll">
          <div class="searchList">
            <span :class="{ historyActive: false}" class="item">
              {{ tenantName }}
            </span>
          </div>
        </div>


      </div>
      <div class="searchHistory">
        <div class="header">
          品牌
          <span class="del" v-if="checkBrand.length" @click="clearHistory(3)"
          >取消选择</span
          >
        </div>
        <div class="hiddenScroll">
          <div class="searchList">
            <span
                v-for="(item, index) in brandList"
                :key="index"
                :class="{ historyActive: checkBrand.indexOf(item.id) > -1 }"
                class="item"
                @click="chooseItem(3, item)"
            >
              {{ item.name }}
            </span>
          </div>
        </div>
      </div>
      <div class="searchHistory">
        <div class="header">
          分类
          <span class="del" v-if="checkCategory.length" @click="clearHistory(2)"
            >取消选择</span
          >
        </div>
        <div class="hiddenScroll">
          <div class="searchList">
            <span
              v-for="(item, index) in categoryList"
              :key="index"
              :class="{ historyActive: checkCategory.indexOf(item.id) > -1 }"
              class="item"
              @click="chooseItem(2, item)"
            >
              {{ item.name }}
            </span>
          </div>
        </div>
      </div>
      <div class="searchHistory">
        <div class="header">
          预约历史
          <span
            class="del"
            @click="clearHistory(4)"
            v-show="bookCheckList.length"
            >取消选择</span
          >
        </div>
        <div class="groupList">
          <el-checkbox-group v-model="bookCheckList">
            <span
              class="checkItem"
              :class="{ checkItemActive: bookCheckList.indexOf(item.id) > -1 }"
              v-for="(item, index) in bookHistoryList"
              :key="index"
            >
              <el-checkbox :label="item.id">
                {{ item.name }}
                <div class="rate">评分：{{ 5.0 }}</div>
              </el-checkbox>
            </span>
          </el-checkbox-group>
        </div>
      </div>
      <div class="searchHistory">
        <div class="header">
          常用设备
          <span
            class="del"
            @click="clearHistory(5)"
            v-show="mostUsedCheckList.length"
            >取消选择</span
          >
        </div>
        <div class="groupList">
          <el-checkbox-group v-model="mostUsedCheckList">
            <span
              class="checkItem"
              :class="{
                checkItemActive: mostUsedCheckList.indexOf(item.id) > -1,
              }"
              v-for="(item, index) in mostUsedList"
              :key="index"
            >
              <el-checkbox :label="item.id">
                {{ item.name }}
                <div class="rate">评分：{{ 5.0 }}</div>
              </el-checkbox>
            </span>
          </el-checkbox-group>
        </div>
      </div>
    </div>
    <KcCascader
        ref="tenantSelect"
        v-model="tenantId"
        :options="baseOptions.tenantOptions"
        :props="{ label: 'name', value: 'id' }"
        @submit="tenantChange"
    ></KcCascader>
  </van-popup>
</template>

<script>
import tenantBaseDataMixinAll from '@/mixins/tenantMixins/tenantBaseDataMixin-all.js';

export default {
  name: "seachPopup",
  mixins: [tenantBaseDataMixinAll],
  data() {
    return {
      show: false,
      historyStorage: [], //历史列表
      bookHistoryList: [], //预约历史列表
      mostUsedList: [], //常用设备列表
      brandList: [], //品牌列表
      categoryList: [], //分类列表
      bookCheckList: [], //选择预约历史
      mostUsedCheckList: [], //选择常用设备
      checkBrand: [],
      checkCategory: [],
      query: "",
      tenantList:[],
      tenantId:'',
      tenantName: '全部科室',
      baseOptions: {
        tenantOptions: [
          {
            id: '',
            name: '全部科室'
          }
        ],
      },
    };
  },
  created() {
    this.historyStorage =
      JSON.parse(localStorage.getItem("historyStorage")) || [];
    this.bookHistory();
    this.mostUsedDevice();
    this.sharePoolConfig();
  },
  methods: {
    tenantChange(e, item) {
      this.tenantName = item.name;
    },
    tenantSelectEvent() {
      this.$refs.tenantSelect.show();
    },
    searchEvent() {
      this.updateHistory();
      this.$emit("searchEvent", {
        query: this.query,
        brandId: this.checkBrand.join(),
        categoryId: this.checkCategory.join(),
        tenantId:this.tenantId,
        isTenantIdChild:1,
      });
    },
    updateHistory() {
      if (this.query == "") {
        return;
      }
      let maxLength = 20;
      for (let i = 0; i < this.historyStorage.length; i++) {
        let info = this.historyStorage[i];
        if (info.name == this.query) {
          return;
        }
      }
      if (this.historyStorage.length >= maxLength) {
        let count = this.historyStorage.length - maxLength + 1;
        this.historyStorage.splice(0, count);
      }
      this.historyStorage.push({ name: this.query });
      localStorage.setItem(
        "historyStorage",
        JSON.stringify(this.historyStorage)
      );
    },
    clearHistory(type) {
      switch (type) {
        case 1:
          localStorage.setItem("historyStorage", JSON.stringify([]));
          this.historyStorage = [];
          this.query = "";
          this.searchEvent();
          break;
        case 2:
          this.checkCategory = [];
          this.searchEvent();
          break;
        case 3:
          this.checkBrand = [];
          this.searchEvent();
          break;
        case 4:
          this.bookCheckList = [];
          break;
        case 5:
          this.mostUsedCheckList = [];
          break;
        default:
          break;
      }
    },
    bookHistory() {
      this.$api.deviceBorrowModule.bookHistory().then((res) => {
        this.bookHistoryList = res.data;
      });
    },
    mostUsedDevice() {
      this.$api.deviceBorrowModule.mostUsedDevice().then((res) => {
        this.mostUsedList = res.data;
      });
    },
    sharePoolConfig() {
      this.$api.deviceBorrowModule.sharePoolConfig().then((res) => {
        this.categoryList = res.data.categoryList;
        this.brandList = res.data.brandList;
      });
    },
    chooseItem(type, item) {
      switch (type) {
        case 1:
          this.query = item.name;
          break;
        case 2:
          let _idxCategory = this.checkCategory.indexOf(item.id);
          if (_idxCategory < 0) {
            this.checkCategory.push(item.id);
          } else {
            this.checkCategory.splice(_idxCategory, 1);
          }
          break;
        case 3:
          let _idxBrand = this.checkBrand.indexOf(item.id);
          if (_idxBrand < 0) {
            this.checkBrand.push(item.id);
          } else {
            this.checkBrand.splice(_idxBrand, 1);
          }
          break;
        default:
          break;
      }
    },
    showPopuo() {
      this.show = true;
    },
  },
};
</script>
<style lang='scss' scoped>
@import "@styles/variables.scss";
.searchContent {
  padding: 10px;
  margin: 0 10px;
  margin-bottom: 8px;
  border-radius: 12px;
  .searchHistory {
    margin-bottom: 8px;
    .header {
      color: $color1;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 8px;
      padding-right: 8px;
      display: flex;
      justify-content: space-between;
      .del {
        font-size: 12px;
        color: #3e73fb;
      }
    }
    .hiddenScroll {
      height: 30px;
      overflow: hidden;
    }
    .searchList {
      height: 40px;
      overflow: auto;
      display: flex;
      .item {
        display: inline-block;
        height: 24px;
        line-height: 22px;
        font-size: 12px;
        padding: 0px 16px;
        margin-right: 12px;
        background: #ffffff;
        border-radius: 12px;
        white-space: nowrap;
        color: #3e73fb;
        border: 1px solid #d9d9d9;
        &.historyActive {
          background: #3e73fb;
          color: #ffffff;
          border: 1px solid #3e73fb;
        }
      }
    }
    .groupList {
      height: 40px;
      overflow: hidden;
      .checkItem {
        display: inline-block;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        border-radius: 18px;
        padding: 0 12px;
        margin-right: 8px;
        margin-bottom: 16px;
        height: 32px;
        .rate {
          text-align: center;
          color: #fee417;
          height: 12px;
          margin-top: -6px;
        }
      }
      .checkItemActive {
        background: #3e73fb;
      }
    }
  }
}
::v-deep .van-search {
  background-color: rgba(0, 0, 0, 0);
}
::v-deep .van-search__action {
  color: #3e73fb;
}
::v-deep .van-search__content {
  background-color: $background_color2;
  border: 1px solid #3e73fb;
}
::v-deep.van-popup {
  background: #f5f7f9;
}
::v-deep .el-checkbox {
  margin-bottom: 0;
}
::v-deep .el-checkbox__input {
  vertical-align: super;
}
::v-deep .el-checkbox__label {
  line-height: 18px;
  font-size: 12px;
}
::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #ffffff;
}
::v-deep .el-checkbox-group {
  display: flex;
  overflow: scroll;
}
</style>