<template>
	<div class="mallItem" @click="toDetails" :class="{disableMallItem:powerOn == 1, ableMallItem:powerOn == 0,}">
		<div class="img"><img style="object-fit: cover" :src="imgUrl" alt="" /></div>
		<div class="mallMain">
			<div class="title">{{ dataInfo.brandName }} {{ dataInfo.modelName }} {{ dataInfo.name }}</div>
			<div class="mallSub">
				<span class="subitem" style="margin-right: 8px">设备评分&nbsp;{{ formatEmpty(dataInfo.levelNum) }}</span>
				<span class="subitem">开机次数&nbsp;{{ formatEmpty(dataInfo.bootCount) }}</span>
				<span
					class="subitem">机时利用率&nbsp;{{ formatFixed(dataInfo.coefficient * 100) == 0 ? 0 : formatFixed(dataInfo.coefficient * 100) + '%' }}</span>
			</div>
			<div class="cost">
				<span class="price">
					¥{{ price }}/{{ formatBillingType(dataInfo.billingType) }}
					<van-icon name="warning-o" class="warning" @click.stop="doubt" v-if="dataInfo.billingType == 0" />
				</span>
				<span class="billingType">按{{ formatBillingType(dataInfo.billingType) }}起租</span>
			</div>
			<div class="suppli">
				<span class="name textEllipsis">{{ dataInfo.tenantName }}</span>
				<span class="right">租借</span>
			</div>
			<div class="suppli">
				<span class="name textEllipsis">{{ depreciationMethod }} </span>
				<span class="billingType">折旧方式</span>
			</div>
			<div class="suppli">
				<span class="name textEllipsis">{{ yearLimit }}年 </span>
				<span class="right">折旧</span>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		formatFixed,
		formatEmpty
	} from '@/assets/JS/utils.js';

	export default {
		props: {
			dataInfo: {
				type: Object,
				default: () => {
					return {};
				}
			}
		},
		data() {
			return {
				formatFixed: formatFixed,
				formatEmpty: formatEmpty
			};
		},
		computed: {
			powerOn() {
				if (typeof this.dataInfo['powerOn'] == 'undefined' || this.dataInfo['powerOn'] == null) {
					return 0;
				}
				return this.dataInfo['powerOn'];
			},
			depreciationMethod() {
				if (typeof this.dataInfo.depreciationMethod == 'undefined' || this.dataInfo.depreciationMethod == null) {
					return '-'
				}
				let depreciationMethod = this.dataInfo.depreciationMethod;
				if (depreciationMethod == '1') {
					return '年限平均法'
				} else if (depreciationMethod == '2') {
					return '工作量法'
				} else if (depreciationMethod == '3') {
					return '双倍余额递减法'
				} else if (depreciationMethod == '4') {
					return '年数总和法'
				}
				return '-'
			},
			yearLimit() {
				if (typeof this.dataInfo.yearLimit == 'undefined' || this.dataInfo.yearLimit == null) {
					return '-'
				}
				return this.dataInfo.yearLimit;
			},
		
			price() {
				let getValue = (data, key, defaultValue) => {
					if (typeof data[key] == 'undefined' || data[key] == null) {
						return defaultValue;
					}
					return data[key];
				};
		
				let money = Number(getValue(this.dataInfo, 'price', 0));
				if (money == 0) {
					return money;
				}
				let moneyStr = money;
				if (money < 10000) {} else if (money >= 10000 && money < 100000000) {
					moneyStr = (money / 10000.0).toFixed(2) + '万';
				} else {
					moneyStr = (money / 100000000.0).toFixed(2) + '亿';
				}
				return moneyStr;
			},
			imgUrl() {
				const _photos = this.dataInfo.photos;
				if (_photos.length > 0) {
					let url = this.$replacePhoto(_photos[0].url + '&scale=0.3');
					return url;
				} else {
					return require('@assets/images/noPhoto.jpg');
				}
			}
		},
		created() {

		},
		methods: {
			doubt() {
				this.$dialog
					.confirm({
						message: '1次使用期限为24小时,请按时归还'
					})
					.then(() => {})
					.catch(() => {});
			},
			toDetails() {
				if (this.powerOn != 0) {
					this.$toast.fail('该设备处于开机状态不可预约');
					return;
				}

				let levelNum = 1;
				if (this.$valueIsExist(this.dataInfo, 'levelNum')) {
					levelNum = this.dataInfo['levelNum'];
				}
				this.$push('/deviceBorrow/mallDetails', {
					id: this.dataInfo.id,
					levelNum: levelNum
				});
			}
		}
	};
</script>
<style lang="scss" scoped>
	@import '@styles/variables.scss';

	.disableMallItem {
		background-color: #D8D9DA;
	}

	.ableMallItem {
		background: $background_color2;
	}



	.mallItem {
		width: 49%;
		margin-bottom: 10px;
		border-radius: 8px;
		overflow: hidden;

		.img {
			width: 100%;
			height: 150px;

			img {
				width: 100%;
				height: 100%;
			}
		}

		.mallMain {
			padding: 8px 10px;

			.title {
				line-height: 14px;
				font-size: 14px;
				height: 28px;
				color: #000000;
				font-weight: 700;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
			}

			.mallSub {
				.subitem {
					font-size: 12px;
					display: inline-block;
					height: 16px;
					line-height: 16px;
					padding: 0 6px;
					background: rgba(62, 115, 251, 0.1);
					border-radius: 4px;
					color: #3e73fb;
				}
			}

			.cost {
				margin: 2px 0;
				display: flex;
				justify-content: space-between;

				.price {
					font-size: 16px;
					color: #fd000d;

					.warning {
						font-size: 16px;
						color: $color3;
						margin-left: 2px;
					}
				}

				.billingType {
					font-size: 12px;
					padding-top: 4px;
					color: $color3;
				}
			}

			.suppli {
				color: $color3;
				font-size: 12px;
				display: flex;
				justify-content: space-between;

				span {
					display: inline-block;
				}

				.name {
					flex: 1;

					img {
						width: 20px;
						height: 20px;
						border-radius: 10px;
						margin-right: 8px;
					}
				}

				.right {
					width: 32px;
					text-align: right;
				}
			}
		}
	}
</style>
